import React, { useRef, useState } from "react";

import Button from "@mui/material/Button";

const HoverVideoButton = ({
  label = "AI Analysis",
  filterMethod,
  dataAnalysisHandler,
}) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const shouldPlayVideo = isHovered || filterMethod;

  console.log("LOGGER hover", {
    label,
    filterMethod,
    dataAnalysisHandler,
  });

  return (
    <div
      style={{
        position: "relative",
        width: "180px",
        height: "80px", // Doubled the height
        marginBottom: "20px",
        overflow: "hidden",
        borderRadius: "4px", // Add rounded corners for better styling
      }}
      onMouseEnter={() => {
        setIsHovered(true);
        if (videoRef.current) {
          videoRef.current.play();
          videoRef.current.playbackRate = 2.0; // Increase playback speed
        }
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        if (videoRef.current && !filterMethod) {
          videoRef.current.pause();
        }
      }}
    >
      <video
        ref={videoRef}
        src="https://www.shutterstock.com/shutterstock/videos/3525274533/preview/stock-footage-circuit-board-electronics-and-tech-on-motherboard-for-big-data-or-communication-on-computer-system.webm"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 0, // Ensure video stays in the background
          visibility: shouldPlayVideo ? "visible" : "hidden", // Show video only on hover or filterMethod
        }}
        muted
        loop
        autoPlay={filterMethod} // Auto-play when filterMethod is true
      />
      <Button
        variant="contained"
        onClick={() => dataAnalysisHandler()}
        style={{
          width: "100%",
          height: "100%", // Adjusted to fill the parent div
          backgroundColor: shouldPlayVideo ? "transparent" : "#e0e0e0",
          color: shouldPlayVideo ? "#fff" : "#9e9e9e",
          zIndex: 1, // Ensure button content is above video
          position: "relative", // Needed for proper stacking
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          fontSize: "16px",
        }}
      >
        <img
          src="https://media.manyhands.charity/static/many-hands-assets/brain.svg"
          alt="icon"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "8px",
          }}
        />
        {label}
      </Button>
    </div>
  );
};

export default HoverVideoButton;
