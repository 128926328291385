import React, { useEffect, useState } from "react";

import BarGraph from "./BarGraph";
import { Button } from "@mui/material";
import DataAnalysis from "./DataAnalysis";
import DynamicSelect from "./Select";
import Hoc from "../../../../hoc/Hoc";
import HoverVideoButton from "../../campaign/HoverButton";
import LineGraph from "./LineGraph";

const CampaignGraph = ({
  login,
  getAllOrganizationCampaigns,
  postCampaignAnalyticsFilter,
  dataAnalysis,
  clearPostCampaignAnalytics,
  campaign,
  charity: analytics,
}) => {
  const {
    user_info: { charity },
  } = login;

  const organizationCampaigns = campaign?.organization_campaigns || [];
  const affiliateIdPrefix = charity.affiliate_id_prefix;
  const loading = campaign?.campaign_analytics_started;
  const filterCampaignAnalytics = campaign?.campaign_analytics || [];
  const dataAnalysisResponse = campaign?.campaign_data_analysis;

  const [data, setData] = useState([]);

  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [activeFilters, setActiveFilters] = useState({
    campaign_id: false,
    utm_medium: false,
    utm_campaign: false,
    timing: false,
  });

  const [queryObject, setQueryObject] = useState({
    campaign_id: "",
    utm_medium: "",
    utm_source: "",
    utm_campaign: "",
  });

  const [hoveredButton, setHoveredButton] = useState(null);
  const [videoButton, setVideoButton] = useState(false);
  const [prevQueryObject, setPrevQueryObject] = useState(queryObject);

  useEffect(() => {
    if (affiliateIdPrefix) {
      getAllOrganizationCampaigns(affiliateIdPrefix);
    }
  }, [affiliateIdPrefix, getAllOrganizationCampaigns]);

  useEffect(() => {
    if (affiliateIdPrefix && filterCampaignAnalytics.length <= 0) {
      postCampaignAnalyticsFilter(queryObject, affiliateIdPrefix);
    }
  }, [queryObject]);

  useEffect(() => {
    if (loading && filterCampaignAnalytics.length > 0) {
      Object.keys(queryObject).forEach((key) => {
        if (queryObject[key] !== prevQueryObject[key]) {
          handleFilterType(key, queryObject[key]);
        }
      });
      setPrevQueryObject({ ...queryObject });
      clearPostCampaignAnalytics(false);
    }
  }, [loading]);

  const handleFilterType = (key, value) => {
    if (queryObject.utm_campaign && queryObject.utm_source) {
      processSourceAndCampaign(
        filterCampaignAnalytics,
        queryObject.utm_source,
        queryObject.utm_campaign
      );
    } else {
      processFilters(key, value);
    }
  };

  const processFilters = (key, value) => {
    const valuesArray = Array.isArray(value) ? value : [value];
    if (valuesArray.length > 0) {
      aggregateAnalyticsData(key, valuesArray);
    }
  };

  const aggregateAnalyticsData = (key, sources) => {
    const aggregatedData = sources.map((source) => {
      const sourceData = {
        title: capitalize(source),
        share_click: 0,
        share_download: 0,
      };

      filterCampaignAnalytics.forEach((item) => {
        if (item[key] === source) {
          sourceData.share_click += item.action_type === "click" ? 1 : 0;
          sourceData.share_download += item.action_type === "download" ? 1 : 0;
        }
      });

      return sourceData;
    });

    setData(aggregatedData);
  };

  const processSourceAndCampaign = (analytics, utmSource, utmCampaign) => {
    const sources = ensureArray(utmSource);
    const campaigns = ensureArray(utmCampaign);

    const result = sources.flatMap((source) =>
      campaigns.map((campaign) => {
        const combinedData = {
          title: `${capitalize(source)} / ${campaign}`,
          share_click: 0,
          share_download: 0,
        };

        analytics.forEach((item) => {
          if (item.utm_source === source && item.utm_campaign === campaign) {
            combinedData.share_click += item.action_type === "click" ? 1 : 0;
            combinedData.share_download +=
              item.action_type === "download" ? 1 : 0;
          }
        });

        return combinedData;
      })
    );

    setData(result);
  };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const ensureArray = (value) => (Array.isArray(value) ? value : [value]);

  const filterUniqueUtmCampaigns = (data) => {
    const uniqueCampaigns = [...new Set(data.map((item) => item.utm_campaign))];
    return uniqueCampaigns;
  };

  const handleButtonClick = (method) => {
    setActiveFilters((prev) => ({
      ...prev,
      [method]: !prev[method],
    }));

    setQueryObject((prev) => {
      let updatedQuery = { ...prev };
      if (method === "utm_medium") {
        if (prev.utm_medium === "") {
          updatedQuery.utm_medium = ["email", "social"];
        } else {
          updatedQuery.utm_medium = "";
          updatedQuery.utm_source = "";
        }
      } else if (method === "utm_campaign") {
        if (prev.utm_campaign === "") {
          updatedQuery.utm_campaign = filterUniqueUtmCampaigns(
            filterCampaignAnalytics
          );
        } else {
          updatedQuery.utm_campaign = "";
        }
      }

      postCampaignAnalyticsFilter(queryObject, affiliateIdPrefix);
      return updatedQuery;
    });
  };

  const handleCampaignChange = (event) => {
    const { value, name } = event.target;

    const updateQueryObject = (updates) => {
      setQueryObject((prev) => ({ ...prev, ...updates }));
      postCampaignAnalyticsFilter(updates, affiliateIdPrefix);
    };

    switch (name) {
      case "utm_medium":
        if (value === "all") {
          updateQueryObject({
            utm_medium: ["email", "social"],
            utm_source: "",
          });
        } else if (value === "social") {
          updateQueryObject({
            utm_medium: "social",
            utm_source: ["facebook", "x", "linkedin"],
          });
        } else {
          updateQueryObject({
            utm_medium: value,
            utm_source: "",
          });
        }
        break;

      case "utm_source":
        updateQueryObject({
          [name]: value === "all" ? ["facebook", "x", "linkedin"] : value,
        });
        break;

      case "campaign_id":
        const selectedCampaignData = organizationCampaigns.find(
          (campaign) => campaign.campaign_title === value
        );
        const filteredId = selectedCampaignData?.campaign_id || "";
        setSelectedCampaign(value);
        updateQueryObject({ [name]: filteredId });
        break;

      case "utm_campaign":
        if (value === "all") {
          updateQueryObject({
            [name]: filterUniqueUtmCampaigns(filterCampaignAnalytics),
          });
        } else {
          updateQueryObject({ [name]: value });
        }
        break;

      default:
        updateQueryObject({ [name]: value });
        break;
    }
  };

  const renderButton = (label, method) => {
    const isActive = activeFilters[method];
    return (
      <Button
        variant="contained"
        onClick={() => handleButtonClick(method)}
        onMouseEnter={() => setHoveredButton(method)}
        onMouseLeave={() => setHoveredButton(null)}
        style={{
          width: "180px",
          marginBottom: "20px",
          backgroundColor:
            isActive && hoveredButton === method
              ? "red"
              : isActive
              ? "var(--color-primary)"
              : "#e0e0e0",
          color:
            isActive && hoveredButton === method
              ? "#fff"
              : isActive
              ? "var(--dark-green)"
              : "#9e9e9e",
        }}
      >
        {hoveredButton === method && isActive ? "X" : label}
      </Button>
    );
  };

  const dataAnalysisHandler = () => {
    setVideoButton((prev)=> !prev)
    dataAnalysis(queryObject, affiliateIdPrefix);
  };

  const utmCampaignOptions =
    filterCampaignAnalytics.length > 0
      ? [...filterUniqueUtmCampaigns(filterCampaignAnalytics), "all"]
      : [""];

  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "80%", display: "flex" }}>
        <div
          style={{
            display: "flex",
            margin: "20px 0",
            width: "100%",
            height: "37px",
          }}
        >
          {activeFilters.campaign_id && (
            <DynamicSelect
              name="campaign_id"
              label="Select Campaign"
              value={selectedCampaign}
              onChange={handleCampaignChange}
              options={organizationCampaigns}
              style={{ width: "20%", margin: "8px 30px 0 0" }}
            />
          )}

          {activeFilters.utm_medium && (
            <>
              <DynamicSelect
                name="utm_medium"
                label="Share Type"
                value={
                  queryObject.utm_medium.includes("email") &&
                  queryObject.utm_medium.includes("social")
                    ? "all"
                    : queryObject.utm_medium
                }
                onChange={handleCampaignChange}
                options={["all", "social", "email"]}
                style={{ width: "20%", margin: "8px 30px 0 0" }}
              />
              {queryObject.utm_medium === "social" && (
                <DynamicSelect
                  name="utm_source"
                  label="Platform"
                  value={
                    Array.isArray(queryObject.utm_source) &&
                    queryObject.utm_source.length === 3 &&
                    queryObject.utm_source.includes("facebook") &&
                    queryObject.utm_source.includes("x") &&
                    queryObject.utm_source.includes("linkedin")
                      ? "all"
                      : queryObject.utm_source
                  }
                  onChange={handleCampaignChange}
                  options={["all", "facebook", "x", "linkedin"]}
                  style={{ width: "20%", margin: "8px 30px 0 0" }}
                />
              )}
            </>
          )}
          {activeFilters.utm_campaign && (
            <DynamicSelect
              name="utm_campaign"
              label="Select Template"
              value={
                Array.isArray(queryObject.utm_campaign)
                  ? "all"
                  : queryObject.utm_campaign
              }
              onChange={handleCampaignChange}
              options={utmCampaignOptions}
              style={{ width: "20%", margin: "8px 30px 0 0" }}
            />
          )}
        </div>
      </div>
      <div style={{ width: "100", display: "flex" }}>
        <div
          style={{
            width: "80%",
            display: "flex",

            alignItems: "center",
          }}
        >
          {videoButton ? (
            <DataAnalysis
              dataAnalysisHandler={dataAnalysisHandler}
              data={dataAnalysisResponse}
            />
          ) : activeFilters.timing ? (
            <LineGraph data={filterCampaignAnalytics} />
          ) : (
            <BarGraph data={data} />
          )}
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            padding: "45px 0 0 20px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h3 style={{ marginBottom: "20px" }}>Filters</h3>
          {renderButton("Campaign", "campaign_id")}
          {renderButton("Platform", "utm_medium")}
          {filterCampaignAnalytics.length > 0 &&
            renderButton("Template", "utm_campaign")}
          {filterCampaignAnalytics.length > 0 &&
            renderButton("Timing", "timing")}
          {filterCampaignAnalytics.length > 0 && (
            <HoverVideoButton
              label={"AI Analysis"}
              filterMethod={videoButton}
              dataAnalysisHandler={dataAnalysisHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hoc(CampaignGraph);
