import "./campaign.css";

import { Box, Button, Modal, Snackbar, Typography } from "@mui/material";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";
import React, { useState } from "react";

import Hoc from "../../../hoc/Hoc";
import MuiAlert from "@mui/material/Alert";

interface SelectedShareCardProps {
  template: any;
  createShareLink: Function;
  campaign: any;
  clearShareLink: Function;
}

const SocialShareTemplate: React.FC<SelectedShareCardProps> = ({
  template,
  createShareLink,
  campaign,
  clearShareLink,
}) => {
  const [platform, setPlatform] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    campaign_social_share_info,
    campaign_social_share_error,
    organization_campaigns,
  } = campaign;
  const affiliate_id_prefix = organization_campaigns[0]?.affiliate_id_prefix;

  const handlePlatformSelection = (provider: string) => setPlatform(provider);

  const handleCreateLink = () => {
    if (!platform) {
      setSnackbarOpen(true);
      return;
    }
    createShareLink({ ...template, platform }, affiliate_id_prefix);
  };

  const handleCopyToClipboard = () => {
    const content = `${campaign_social_share_info.post_text}\n${campaign_social_share_info.share_url}`;
    navigator.clipboard.writeText(content).then(
      () => setModalOpen(true),
      (err) => console.error("Failed to copy content:", err)
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    clearShareLink();
    setPlatform(null);
  };

  const closeSnackbar = () => setSnackbarOpen(false);

  const formattedPostText = template.post_text
    .split("\n")
    .map((line: any) => line.trim())
    .filter(Boolean);

  const renderSocialButton = (provider: string, Icon: React.ElementType) => (
    <div
      className="social-share-icon-button"
      style={{
        backgroundColor: platform === provider ? "#BBBBBB" : "#e6e6e6",
      }}
      onClick={() => handlePlatformSelection(provider)}
    >
      <Icon fontSize="small" />
    </div>
  );

  const actionButton = (
    <Button
      variant="contained"
      onClick={
        campaign_social_share_info && !campaign_social_share_error && platform
          ? handleCopyToClipboard
          : handleCreateLink
      }
      style={{
        backgroundColor: platform ? "var(--color-primary)" : "#e0e0e0",
        color: platform ? "var(--dark-green)" : "#9e9e9e",
      }}
    >
      {campaign_social_share_info && !campaign_social_share_error && platform
        ? "COPY & PASTE"
        : "CREATE SHARE LINK"}
    </Button>
  );

  return (
    <div className="social-share-template-container">
      <div className="social-share-template-text-container">
        {formattedPostText.map((line: any, index: number) => (
          <p key={index}>{line}</p>
        ))}
      </div>

      <div className="social-share-image-button-container">
        <img
          src={template.image}
          alt={template.post_text}
          className="template-image"
        />

        <div className="social-share-icon-container">
          {renderSocialButton("facebook", Facebook)}
          {renderSocialButton("linkedin", LinkedIn)}
          {renderSocialButton("x", Twitter)}
        </div>

        {actionButton}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={closeSnackbar}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Please select a social platform before creating a link.
        </MuiAlert>
      </Snackbar>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="copy-success-modal"
        aria-describedby="copy-success-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <Typography
            id="copy-success-modal"
            variant="h6"
            sx={{ fontSize: 24 }}
          >
            Copy Success!
          </Typography>
          <Typography
            id="copy-success-description"
            sx={{ mt: 2, fontSize: 18 }}
          >
            {`Copied link to clipboard successfully! Paste in ${
              platform
                ? platform.charAt(0).toUpperCase() + platform.slice(1)
                : "E.g. Facebook"
            }.`}
          </Typography>
          <Button
            variant="contained"
            onClick={closeModal}
            sx={{
              mt: 2,
              backgroundColor: "#6b21a8",
              "&:hover": { backgroundColor: "#5a1a8a" },
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Hoc(SocialShareTemplate);
