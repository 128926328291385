import "./campaign.css";

import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailCard from "./EmailCard";
import SelectedShareCard from "./SocialShareCard";

const SelectedCampaign = ({ selectedCampaign, onBack }) => {
  const [shareMethod, setShareMethod] = useState(null);
  return (
    <div className="selected-campaign-container">
      <div className="left-panel">
        <div className="back-button-container">
          <IconButton
            onClick={onBack}
            aria-label="back"
            className="back-button"
          >
            <ArrowBackIcon fontSize="large" />
            Back
          </IconButton>
          <div style={{ marginTop: "30px" }}>
            {shareMethod === null && (
              <>
                <h1>SELECT</h1>
                <h3>Share Type</h3>
                <p>
                  Choose how you'd like to share this message! You can either
                  share it on your preferred social media platform or send it
                  via email to spread the word.
                </p>
              </>
            )}
            {shareMethod === "social" && (
              <>
                <h1>SELECT</h1>
                <h3>Social Share Template</h3>
                <p>
                  Copy and paste on your preferred social media platform. Click
                  the button to paste image, text, and post!
                </p>
              </>
            )}
            {shareMethod === "email" && (
              <>
                <h1>SELECT</h1>
                <h3>Email Template</h3>
                <ul className="email-steps">
                  <li>
                    <p>⚫&nbsp;&nbsp; Select email template</p>
                  </li>
                  <li>
                    <p>⚫&nbsp;&nbsp; Select email bulk mailer</p>
                  </li>
                  <li>
                    <p>⚫&nbsp;&nbsp; Copy and Paste</p>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="button-group">
          <Button
            variant="contained"
            onClick={() => setShareMethod("social")}
            style={{
              backgroundColor:
                shareMethod === "social" || shareMethod === null
                  ? "var(--color-primary)"
                  : "#e0e0e0",
              color:
                shareMethod === "social" || shareMethod === null
                  ? "var(--dark-green)"
                  : "#9e9e9e",
            }}
          >
            Social Templates
          </Button>
          <Button
            variant="contained"
            onClick={() => setShareMethod("email")}
            style={{
              backgroundColor:
                shareMethod === "email" ? "var(--color-primary)" : "#e0e0e0",
              color: shareMethod === "email" ? "var(--dark-green)" : "#9e9e9e",
            }}
          >
            Email Template
          </Button>
        </div>
      </div>
      <div className="right-panel">
        {shareMethod === "email" ? (
          <>
            <h3 style={{ marginBottom: "25px" }}>Email Templates</h3>
            <div className="right-panel-social-share">
              {selectedCampaign.email_templates.map((template, index) => (
                <EmailCard email_object={template} key={index}/>
              ))}
            </div>
          </>
        ) : (
          <>
            <h3 style={{ marginBottom: "25px" }}>Social Share Templates</h3>
            <div className="right-panel-social-share">
              {selectedCampaign.social_share_templates.map(
                (template, index) => (
                  <SelectedShareCard template={template} key={index} />
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SelectedCampaign;
