import "./graph.css";

import React, { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import Bar from "../../../static/bar.jsx";
import BarGraph from "./campaign/CampaignAnalytics";
import Circle from "../../../static/circle.jsx";
import CircleGraph from "./CircleGraph";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Hoc from "../../../hoc/Hoc";
import Line from "../../../static/line.jsx";
import LineGraph from "./LineGraph";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";

interface IGraph {
  getCharityTransaction: any;
  charity: any;
  login: any;
}
const Graph: React.FC<IGraph> = ({ getCharityTransaction, charity, login }) => {
  const [graph, setGraph] = useState("Revenue");

  return (
    <>
      <div className="campaign-header-container">
        <h1>{graph.toUpperCase()}</h1>
        <h1 className="campaign-header-select-header-text">Data</h1>
      </div>
      <div
        style={{
          marginTop: "20px",
          height: "100%",
        }}
      ></div>
      <div className="graph-parent-container" style={{ marginTop: "20px" }}>
        <div className="graph-child--graph-container">
          {graph === "Revenue" && <LineGraph />}

          {graph === "Campaign" && <BarGraph />}
        </div>

        <div className="graph-child--buttons-container">
          <div
            className="graph-type-button"
            onClick={() => setGraph("Revenue")}
          >
            <Line graph={graph} />
            Total Revenue
          </div>

          <div
            className="graph-type-button"
            onClick={() => setGraph("Campaign")}
          >
            <Bar graph={graph} />
            Campaign Analytics
          </div>
        </div>
      </div>
    </>
  );
};

export default Hoc(Graph);
