import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import Hoc from "../../../../hoc/OrganizationSignUpHoc";
import Payments from "./Payments";
import Transactions from "./Transactions";
import axios from "axios";

interface IPaymentsTab {
  login: any;
}
const PaymentsParent: React.FC<IPaymentsTab> = ({ login }) => {
  const { user_info } = login;
  const uuid = user_info && user_info.user.uuid;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [account, setAccount] = useState<any>(null);

  const { enabled, fixIssuesUrl, transactions, login_link } = account || {};

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.manyhands.charity/payment-account-setup/organization-account-status/${uuid}`
        );

        setAccount(response.data);
      } catch (err: any) {
        setError(
          err.message || "An error occurred while fetching the account details."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAccountDetails();
  }, [uuid]);

  if (loading) {
    return <div className="update-organization-container">Loading...</div>;
  }

  if (error) {
    return <div className="update-organization-container">Error: {error}</div>;
  }

  return (
    <>
      <div className="campaign-header-container">
        <h1>PAYMENTS</h1>
      </div>

      <div
        className="graph-child--graph-container"
        style={{ padding: "20px 30px", marginTop: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "50%",
            maxHeight: "100%",
            minHeight: "65vh",
            height: "65vh",
          }}
        >
          {enabled && fixIssuesUrl === null ? (
            <Transactions transactions={transactions} login_link={login_link} />
          ) : !enabled && fixIssuesUrl ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h2>Update Stripe Account</h2>
              <h4>If all details have been added give it a few minutes</h4>
              <h4>or contact Many Hands</h4>
              <Button
                variant="contained"
                color="primary"
                href={fixIssuesUrl}
                style={{
                  marginTop: '20px',
                  width: "200px",
                  backgroundColor: "var(--color-primary)",
                  color: "var(--dark-green)",
                }}
              >
                Complete Onboarding
              </Button>
            </div>
          ) : (
            <Payments />
          )}
        </div>
      </div>
    </>
  );
};

export default Hoc(PaymentsParent);
