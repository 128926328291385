import * as React from "react";

const SvgComponent = ({graph}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 52.917 52.917"
  >
    <g transform="translate(215.23 81.831)">
      <rect
        width={52.917}
        height={52.917}
        x={-215.23}
        y={-81.831}
        rx={7.889}
        ry={7.889}
        style={{
          fill: graph === "Revenue" ? "#BBBBBB" : "#e6e6e6",
          strokeWidth: 0.144,
        }}
      />
      <rect
        width={1.111}
        height={44.979}
        x={-209.87}
        y={-77.862}
        rx={0}
        ry={0}
        style={{
          fill: "#4d4d4d",
          strokeWidth: 0.540076,
        }}
      />
      <rect
        width={1.111}
        height={44.979}
        x={-33.994}
        y={164.89}
        rx={0}
        ry={0}
        style={{
          fill: "#4d4d4d",
          strokeWidth: 0.540076,
        }}
        transform="rotate(90)"
      />
      <path
        d="M-201.779-47.515c.945-5.008 3.19-9.584 7.933-13.326 2.153-2.848 4.288-5.719 8.09-6.505 5.505 3.049 6.516 7.096 8.41 10.947 2.538-3.2 5.076-3.134 7.614-3.49"
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#6b21a8",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <circle
        cx={-201.063}
        cy={-46.8}
        r={1.984}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.78174,
        }}
      />
      <circle
        cx={-201.063}
        cy={-46.8}
        r={1.269}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.49999,
        }}
      />
      <circle
        cx={-177.386}
        cy={-56.399}
        r={1.984}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.78174,
        }}
      />
      <circle
        cx={-185.517}
        cy={-66.975}
        r={1.984}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.78174,
        }}
      />
      <circle
        cx={-193.648}
        cy={-61.317}
        r={1.984}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.78174,
        }}
      />
      <circle
        cx={-185.517}
        cy={-66.975}
        r={1.269}
        style={{
          fill: "#00c7a4",
          fillOpacity: 1,
          strokeWidth: 0.49999,
        }}
      />
      <circle
        cx={-169.256}
        cy={-60.206}
        r={1.984}
        style={{
          fill: "#6b21a8",
          fillOpacity: 1,
          strokeWidth: 0.78174,
        }}
      />
    </g>
  </svg>
)
export default SvgComponent
