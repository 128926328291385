import "./update-organization.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Hoc from "../../../hoc/OrganizationSignUpHoc";
import { states } from "../../../utils/charity";
import { styled } from "@mui/material/styles";

const Input = styled("input")({
  display: "none",
});

interface IOrganizationSignUp {
  login: any;
  patchOrganizationInformation: Function;
  updateOrganizationError: Function;
  completeCreateOrganization: Function;
}

const OrganizationSignUp: React.FC<IOrganizationSignUp> = ({
  completeCreateOrganization,
  patchOrganizationInformation,
  updateOrganizationError,
  login,
}) => {
  const { user_info, update_organization } = login;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState<string>("");
  const [organizationType, setOrganizationType] = useState("");
  const [state, setState] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (user_info.charity) {
      const {
        title,
        description,
        logo,
        organization_type,
        state,
        user_organization_id,
      } = user_info.charity;

      setTitle(title);
      setDescription(description);
      setLogo(logo);
      setOrganizationType(organization_type);
      setState(state);
      setId(user_organization_id);
    }
  }, [user_info.charity]);

  useEffect(() => {
    if (user_info?.user) {
      const { uuid } = user_info.user;
      setId(uuid);
    }
  }, [user_info.user]);

  const resetOrganizatioUpdate = () => {
    updateOrganizationError(null);
  };

  useEffect(() => {
    setTimeout(resetOrganizatioUpdate, 2500);
  }, [update_organization]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        if (typeof e.target?.result === "string") {
          setLogo(e.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOrganizationTypeChange = (event: any) => {
    setOrganizationType(event.target.value);
  };

  const handleOrganizationState = (event: any) => {
    setState(event.target.value);
  };

  const formFieldsStep2 = [
    {
      id: "title",
      label: "Organization Name",
      type: "text",
      value: title,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setTitle(e.target.value),
    },
    {
      id: "description",
      label: "Organization Description",
      type: "text",
      multiline: true,
      rows: 4,
      value: description,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setDescription(e.target.value),
    },
  ];

  const isTitleValid = title.trim() !== "";
  const isDescriptionValid = description.trim() !== "";
  const isOrganizationTypeValid = organizationType !== "";
  const isOrganizationStateValid = state !== "";
  const isLogoUploaded = logo !== null;

  const handleOrganizationSubmit = () => {
    const data = {
      title,
      description,
      organizationType,
      state,
      logo,
    };
    if (user_info.charity === undefined) {
      completeCreateOrganization(id, data);
    } else {
      patchOrganizationInformation(id, data);
    }
  };
  const disable =
    !isTitleValid ||
    !isDescriptionValid ||
    !isOrganizationTypeValid ||
    !isOrganizationStateValid ||
    !isLogoUploaded;

  return (
    <>
      {update_organization ? (
        <div className="campaign-header-container">
          <h1>UPDATED</h1>
        </div>
      ) : (
        <>
          <div className="campaign-header-container">
            <h1>UPDATE</h1>
          </div>
          <div
            className="graph-child--graph-container"
            style={{ padding: "20px 30px", marginTop: "20px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "50%",
                maxHeight: "100%",
                minHeight: "65vh",
                height: "65vh",
              }}
            >
              {formFieldsStep2.map((field) => (
                <TextField
                  key={field.id}
                  type={field.type}
                  label={field.label}
                  multiline={field.multiline ?? false}
                  rows={field.rows ?? 1}
                  value={field.value}
                  onChange={field.onChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
              ))}

              <FormControl fullWidth margin="normal">
                <InputLabel id="organization-type-label">
                  Organization Type
                </InputLabel>
                <Select
                  labelId="organization-type-label"
                  id="organization-type"
                  value={organizationType}
                  label="Organization Type"
                  onChange={handleOrganizationTypeChange}
                >
                  <MenuItem value="nonprofit">Non-Profit</MenuItem>
                  <MenuItem value="forprofit">For-Profit</MenuItem>
                  <MenuItem value="government">Government</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel id="organization-state-label">
                  Organization Location
                </InputLabel>
                <Select
                  labelId="organization-state-label"
                  id="organization-type"
                  value={state}
                  label="Organization Location"
                  onChange={handleOrganizationState}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state.toLowerCase()}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="upload-logo-container-update-admin">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    onClick={handleOrganizationSubmit}
                    style={{
                      width: "200px",
                      backgroundColor: "var(--color-primary)",
                      color: "var(--dark-green)",
                    }}
                  >
                    Upload Picture
                  </Button>
                </label>

                {logo ? (
                  <img
                    src={logo}
                    alt="Uploaded"
                    className="organization-logo-upload-admin"
                  />
                ) : (
                  <div className="organization-logo-upload-placeholder-admin"></div>
                )}
              </div>

              <Button
                variant="contained"
                onClick={handleOrganizationSubmit}
                style={{
                  width: "200px",
                  backgroundColor: disable ? "var(--color-primary)" : "#e0e0e0",
                  color: disable ? "var(--dark-green)" : "#9e9e9e",
                }}
                disabled={disable}
              >
                Submit
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Hoc(OrganizationSignUp);
