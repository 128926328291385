export const datePickerValues = (value) => {
  const today = new Date();

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const formattedToday = today.toISOString();
  const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString();

  const isoStart =
    value === null
      ? formattedThirtyDaysAgo
      : value && value[0] && value[0].$d && new Date(value[0].$d).toISOString();

  const isoEnd =
    value && value[1] && value[1].$d
      ? new Date(value[1].$d).toISOString()
      : formattedToday;

  const formatDateToMDY = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  };

  const displayStart = formatDateToMDY(isoStart);
  const displayEnd = formatDateToMDY(isoEnd);

  return { start: displayStart, end: displayEnd };
};
