import {
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
} from "victory";

import React from "react";
import { groupBy } from "lodash";

const formatHour = (hour) => {
  const period = hour < 12 ? "AM" : "PM";
  const standardHour = hour % 12 || 12; // Convert 0 hour to 12 for AM
  return `${standardHour} ${period}`;
};

const preprocessData = (data) => {
  const groupedData = groupBy(data, "action_type");

  const countActionsByTime = (actions) => {
    const hourCounts = {};
    actions.forEach((action) => {
      const hour = new Date(action.action_time).getHours();
      hourCounts[hour] = (hourCounts[hour] || 0) + 1;
    });
    return Object.entries(hourCounts).map(([hour, count]) => ({
      hour: parseInt(hour),
      count,
    }));
  };

  return {
    clicks: countActionsByTime(groupedData.click || []),
    downloads: countActionsByTime(groupedData.download || []),
  };
};

const LineGraph = ({ data }) => {
  const { clicks, downloads } = preprocessData(data);

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 20 }}
        padding={{ top: 50, bottom: 50, left: 50, right: 50 }}
        width={800}
        height={400}
        style={{
          background: { fill: "rgb(15 23 42 / 14%)" }, // Dark background for the chart
        }}
      >
        {/* Legend */}
        <VictoryLegend
          x={300}
          y={10}
          orientation="horizontal"
          gutter={20}
          style={{
            labels: { fontSize: 12, fill: "#000" }, // Legend label styling
          }}
          data={[
            { name: "Clicks", symbol: { fill: "#00c7a4" } },
            { name: "Downloads", symbol: { fill: "var(--color-primary)" } },
          ]}
        />

        {/* X-Axis */}
        <VictoryAxis
          label="Time of Day"
          tickFormat={(hour) => formatHour(hour)} // Format the hour as AM/PM
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 14, padding: 30, fill: "#000" },
            ticks: { stroke: "#756f6a", size: 5 },
            tickLabels: { fontSize: 10, padding: 5, fill: "#000" },
          }}
        />

        {/* Y-Axis */}
        <VictoryAxis
          dependentAxis
          label="Actions"
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 14, padding: 40, fill: "#000" },
            ticks: { stroke: "#756f6a", size: 5 },
            tickLabels: { fontSize: 10, padding: 5, fill: "#000" },
          }}
        />

        {/* Clicks Line */}
        <VictoryLine
          data={clicks}
          x="hour"
          y="count"
          style={{
            data: { stroke: "#00c7a4", strokeWidth: 2 },
            labels: { fontSize: 10, fill: "#000" },
          }}
          labels={({ datum }) => `${datum.count}`}
          labelComponent={<VictoryTooltip />}
        />

        {/* Downloads Line */}
        <VictoryLine
          data={downloads}
          x="hour"
          y="count"
          style={{
            data: { stroke: "var(--color-primary)", strokeWidth: 2 },
            labels: { fontSize: 10, fill: "#000" },
          }}
          labels={({ datum }) => `${datum.count}`}
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </div>
  );
};

export default LineGraph;