import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
} from "victory";

import React from "react";

const BarGraph = ({ data }) => {
  // Chart dimensions
  const chartWidth = 800;
  const maxBarWidth = 40; // Maximum width for bars
  const barSpacing = 20; // Space between bars

  // Calculate dynamic bar width
  const barWidth = Math.min(
    maxBarWidth,
    (chartWidth - barSpacing * (data.length - 1)) / (2 * data.length)
  );

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 20 }}
        padding={{ top: 35, bottom: 0, left: 40, right: 10 }}
        width={chartWidth}
        height={400}
        style={{
          background: { fill: "rgb(15 23 42 / 14%)" }, // Dark background for the chart
        }}
      >
        {/* Legend */}
        <VictoryLegend
          x={350} // Position legend horizontally
          y={10} // Position legend vertically
          orientation="horizontal"
          gutter={20} // Space between legend items
          style={{
            labels: { fontSize: 12, fill: "#000" }, // Legend label styling
          }}
          data={[
            { name: "Clicks", symbol: { fill: "#00c7a4" } }, // Matches the first bar color
            { name: "Downloads", symbol: { fill: "var(--color-primary)" } }, // Matches the second bar color
          ]}
        />

        {/* X-Axis */}
        <VictoryAxis
          label="Categories"
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 14, padding: 40, fill: "#000" }, // Title for X-Axis
            ticks: { stroke: "#756f6a", size: 5 },
            tickLabels: { fontSize: 10, padding: 5, fill: "#000" }, // Labels styling
            grid: { stroke: "#555555", strokeDasharray: "4 4" }, // Gridline styling
          }}
          tickFormat={data ? data.map((d) => d.title) : []} // Formatting tick labels
        />

        {/* Y-Axis */}
        <VictoryAxis
          dependentAxis
          label="Values"
          style={{
            axis: { stroke: "#756f6a" },
            axisLabel: { fontSize: 14, padding: 40, fill: "#000" }, // Title for Y-Axis
            ticks: { stroke: "#756f6a", size: 5 },
            tickLabels: { fontSize: 10, padding: 5, fill: "#000" }, // Labels styling
            grid: { stroke: "#555555", strokeDasharray: "4 4" }, // Gridline styling
          }}
        />

        {/* First Bar Series */}
        <VictoryBar
          data={data}
          x="title"
          y="share_click"
          barWidth={barWidth}
          style={{
            data: { fill: "#00c7a4" }, // Bar color
            labels: { fontSize: 10, fill: "#000" }, // Label color
          }}
          labels={({ datum }) => `${datum.share_click}`}
          labelComponent={<VictoryTooltip />}
        />

        {/* Second Bar Series */}
        <VictoryBar
          data={data}
          x="title"
          y="share_download"
          barWidth={barWidth}
          style={{
            data: { fill: "var(--color-primary)" }, // Bar color
            labels: { fontSize: 10, fill: "#000" }, // Label color
          }}
          labels={({ datum }) => `${datum.share_download}`}
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </div>
  );
};

export default BarGraph;
