export const GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED =
  "GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED";
export const GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR =
  "GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR";

export const CREATE_CAMPAIGN_SHARE_LINK_FULFILLED =
  "CREATE_CAMPAIGN_SHARE_LINK_FULFILLED";
export const CREATE_CAMPAIGN_SHARE_LINK_ERROR =
  "CREATE_CAMPAIGN_SHARE_LINK_ERROR";
export const CLEAR_CAMPAIGN_SHARE_LINK = "CLEAR_CAMPAIGN_SHARE_LINK";

export const POST_CAMPAIGN_ANALYTICS_STARTED =
  "POST_CAMPAIGN_ANALYTICS_STARTED";
export const POST_CAMPAIGN_ANALYTICS_FULFILLED =
  "POST_CAMPAIGN_ANALYTICS_FULFILLED";
export const POST_CAMPAIGN_ANALYTICS_ERROR = "POST_CAMPAIGN_ANALYTICS_ERROR";

export const POST_DATA_ANALYSIS_FULFILLED = "POST_DATA_ANALYSIS_FULFILLED";
export const POST_DATA_ANALYSIS_ERROR = "POST_DATA_ANALYSIS_ERROR";