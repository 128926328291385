import "./update-organization.css";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import Hoc from "../../../hoc/OrganizationSignUpHoc";

const OrganizationSignUp = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [percent, setPercent] = useState("");

  const handleSubmit = () => {
    console.log({
      organizationName,
      organizationEmail,
      percent,
    });
  };

  const disable = organizationName && organizationEmail && percent;
  return (
    <>
      <div className="campaign-header-container">
        <h1>PARTNERSHIP</h1>
      </div>

      <div
        className="graph-child--graph-container"
        style={{ padding: "20px 30px", marginTop: "20px", }}
      >
        <div style={{  width: '50%', display: 'flex',flexDirection: 'column' ,}}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Organization Name"
            variant="outlined"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Organization Email"
            variant="outlined"
            value={organizationEmail}
            onChange={(e) => setOrganizationEmail(e.target.value)}
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="percent-label">Percent</InputLabel>
          <Select
            labelId="percent-label"
            id="percent-select"
            value={percent}
            label="Percent"
            onChange={(e) => setPercent(e.target.value)}
            fullWidth
          >
            {Array.from({ length: 11 }, (_, i) => i * 5).map((value) => (
              <MenuItem key={value} value={value}>
                {value}%
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={{
            marginTop: '20px',
            width: "200px",
            backgroundColor: disable ? "var(--color-primary)" : "#e0e0e0",
            color: disable ? "var(--dark-green)" : "#9e9e9e",
          }}
          disabled={!disable}
        >
          Submit
        </Button>
      </div>
      
    </>
  );
};

export default Hoc(OrganizationSignUp);
