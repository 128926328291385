import "./campaign.css";

import React, { useEffect, useState } from "react";

import CampaignContainer from "./CampaignSelectCard";
import Hoc from "../../../hoc/Hoc";
import SelectedCampaign from "./SelectedCampaign"; // Import SelectedCampaign component

interface IMainCampaign {
  getAllOrganizationCampaigns: (affiliateIdPrefix: string) => void;
  createShareLink: Function;
  login: any;
  campaign: any;
}

const MainCampaign: React.FC<IMainCampaign> = ({
  login,
  getAllOrganizationCampaigns,
  campaign,
}) => {
  const affiliateIdPrefix = login?.user_info?.charity?.affiliate_id_prefix;
  const organizationCampaigns = campaign?.organization_campaigns || [];

  
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );
  const [selectedCampaign, setSelectedCampaign] = useState<any | null>(null);

  useEffect(() => {
    if (affiliateIdPrefix) {
      getAllOrganizationCampaigns(affiliateIdPrefix);
    }
  }, [affiliateIdPrefix, getAllOrganizationCampaigns]);

  const handleSelectCampaign = (campaignId: string) => {
    setSelectedCampaignId(campaignId);
    const campaign = organizationCampaigns.find(
      (campaign: any) => campaign.campaign_id === campaignId
    );
    setSelectedCampaign(campaign);
  };

  const clearSelectedCampaign = () => {
    setSelectedCampaignId(null);
    setSelectedCampaign(null);
  };

  if (!affiliateIdPrefix) {
    return (
      <p>Affiliate ID prefix is missing. Please verify your login details.</p>
    );
  }

  if (organizationCampaigns.length === 0) {
    return <p>No campaigns available.</p>;
  }

  return (
    <div className="campaign-main-container">
      {selectedCampaignId && selectedCampaign ? (
        <SelectedCampaign
          selectedCampaign={selectedCampaign}
          onBack={clearSelectedCampaign}
        />
      ) : (
        <>
          <div className="campaign-header-container">
            <h1>CAMPAIGN</h1>
            <h1 className="campaign-header-select-header-text">Select</h1>
          </div>
          <div className="campaign-list">
            {organizationCampaigns.map((campaign: any) => (
              <CampaignContainer
                key={campaign.campaign_id}
                campaign_id={campaign.campaign_id}
                campaign_title={campaign.campaign_title}
                image={campaign.image}
                start_date={campaign.start_date}
                end_date={campaign.end_date}
                colors={campaign.colors}
                social_share_templates={campaign.social_share_templates}
                onSelectCampaign={handleSelectCampaign}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Hoc(MainCampaign);
