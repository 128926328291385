import React, { useState } from "react";

const renderContent = (text, expandableSections, expanded, setExpanded) => {
  const lines = text.split("\n").filter((line) => line.trim() !== "");
  let skipUntilNextSection = false;
  let listOpen = false;

  const renderLine = (line, index) => {
    const trimmedLine = line.trim().replace(/\*\*/g, "");

    if (line.startsWith("### ")) {
      const sectionTitle = trimmedLine.replace("### ", "");
      const isExpandable = expandableSections.includes(sectionTitle);

      if (isExpandable) {
        skipUntilNextSection = expanded[sectionTitle] ? false : true;
      }

      return (
        <div key={index}>
          <h3
            style={{
              ...headerStyle,
              cursor: isExpandable ? "pointer" : "default",
            }}
            onClick={() =>
              isExpandable &&
              setExpanded((prev) => ({
                ...prev,
                [sectionTitle]: !prev[sectionTitle],
              }))
            }
          >
            {sectionTitle}
          </h3>
          {isExpandable && expanded[sectionTitle] && (
            <div style={{ marginLeft: "20px" }}>
              {lines.slice(index + 1).map((subLine, subIndex) => {
                if (subLine.startsWith("### ")) return null;
                return renderLine(subLine, `${index}-${subIndex}`);
              })}
            </div>
          )}
        </div>
      );
    } else if (skipUntilNextSection) {
      return null;
    } else if (line.startsWith("- ")) {
      if (!listOpen) {
        listOpen = true;
        return (
          <ul key={`ul-start-${index}`}>
            <li style={listItemStyle}>{trimmedLine.replace("- ", "")}</li>
          </ul>
        );
      }
      return (
        <li key={index} style={listItemStyle}>
          {trimmedLine.replace("- ", "")}
        </li>
      );
    } else {
      const closingList = listOpen ? <ul key={`ul-end-${index}`} /> : null;
      listOpen = false;
      return (
        <>
          {closingList}
          <p key={index} style={paragraphStyle}>
            {trimmedLine}
          </p>
        </>
      );
    }
  };

  const renderedContent = [];
  let skipLines = false;

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith("### ")) {
      const sectionTitle = lines[i].replace("### ", "");
      if (expandableSections.includes(sectionTitle)) {
        skipLines = expanded[sectionTitle];
        renderedContent.push(renderLine(lines[i], i));
      } else {
        skipLines = false;
        renderedContent.push(renderLine(lines[i], i));
      }
    } else if (!skipLines) {
      renderedContent.push(renderLine(lines[i], i));
    }
  }

  if (listOpen) {
    renderedContent.push(<ul key="ul-final" />);
  }

  return <div>{renderedContent}</div>;
};

const OpenAIAnalysis = ({ data }) => {
  const [expanded, setExpanded] = useState({
    "Summary of Findings": false,
    "Actionable Recommendations": false,
  });

  const expandableSections = [
    "Summary of Findings",
    "Actionable Recommendations",
  ];

  return (
    <div style={{ padding: "20px" }}>
      {data ? (
        <>
          <h2 style={{ marginBottom: "20px" }}>OpenAI Data Analysis</h2>
          {renderContent(data, expandableSections, expanded, setExpanded)}
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <h3>...Loading</h3>
        </div>
      )}
    </div>
  );
};

const headerStyle = {
  width: "100%",
  backgroundColor: "#c2c8d2)",
  color: "var(--color-primary)",
  padding: "10px",
  borderRadius: "5px",
  marginBottom: "10px",
  boxShadow:
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
};

const listItemStyle = {
  marginLeft: "20px",
  marginBottom: "5px",
};

const paragraphStyle = {
  marginLeft: "20px",
  marginBottom: "10px",
  lineHeight: "1.2",
};

export default OpenAIAnalysis;
