import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  useRoutes,
} from "react-router-dom";

import About from "../pages/About";
import AuthorizationHandler from "../pages/AuthorizationHandler"
import CharityLandingPage from "../pages/CharityLandingPage";
import CharityPage from "../pages/CharityPage";
import Contact from "../components/contact/Contact";
import Coupons from "../pages/Coupons";
import Email from "../pages/Email";
import Faq from "../pages/FAQ";
import Home from "../pages/Home";
import Login from "../pages/Login";
import OrganizationPaymentReturn from "../pages/OrganizationReturn";
import OrganizationSignUp from "../components/sections/charity-landing-page/organizationSignUp/OrganizationSignUp";
import Payments from "../pages/Payments";
import Redirect from "../pages/Redirect";
import ResetPasswordRequest from "../pages/reset-password/ResetPasswordRequest";
import Share from "../pages/Share";
import SignUp from "../pages/SignUp";
import Terms from "../pages/Terms";
import Uninstall from "../pages/uninstall";
import Unsubscribe from "../pages/Unsubscribe";
import UpdatePassword from "../pages/reset-password/UpdatePassword";

const Unprotected = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/charity_landing_page", element: <CharityLandingPage /> },
    { path: "/terms", element: <Terms /> },
    { path: "/about", element: <About /> },
    { path: "/fundraiser-sign-up", element: <OrganizationSignUp /> },
    { path: "/faq", element: <Faq /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/charity/:title/:id", element: <CharityPage /> },
    { path: "/share", element: <Share /> },
    { path: "/uninstall_user", element: <Uninstall /> },
    { path: "/unsubscribe", element: <Unsubscribe /> },
    { path: "/coupons-discounts", element: <Coupons /> },
    { path: "/login", element: <Login /> },
    { path: "/redirect", element: <Redirect /> },
    { path: "/email", element: <Email /> },
    { path: "/contact", element: <Contact /> },
    { path: "/reset-password-request", element: <ResetPasswordRequest /> },
    { path: "/update-password", element: <UpdatePassword /> },
    { path: "/payments", element: <Payments /> },
    { path: "/organization-return", element: <OrganizationPaymentReturn /> },
    {path:"/authorization-handler", element: <AuthorizationHandler />}
  ]);
  return routes;
};

const RouteTree: React.FC = (props): JSX.Element => {
  return (
    <Router>
      <Unprotected />
    </Router>
  );
};
export default RouteTree;