import * as actionTypes from "../types/organizationCampaign";

const initialState = {
  organization_campaigns: [],
  organization_campaigns_error: "",
  campaign_social_share_info: null,
  campaign_social_share_error: false,
  campaign_analytics: [],
  campaign_analytics_started: null,
  campaign_analytics_error: false,
  campaign_data_analysis: ``,
};

export default (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED:
      return {
        ...state,
        organization_campaigns: action.payload,
      };
    case actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR:
      return {
        ...state,
        organization_campaigns_error: true,
      };
    case actionTypes.CREATE_CAMPAIGN_SHARE_LINK_FULFILLED:
      return {
        ...state,
        campaign_social_share_info: action.payload,
      };
    case actionTypes.CREATE_CAMPAIGN_SHARE_LINK_ERROR:
      return {
        ...state,
        campaign_social_share_error: false,
      };
    case actionTypes.CLEAR_CAMPAIGN_SHARE_LINK:
      return {
        ...state,
        campaign_social_share_info: action.payload,
      };
    case actionTypes.POST_CAMPAIGN_ANALYTICS_STARTED:
      return {
        ...state,
        campaign_analytics_started: false,
      };
    case actionTypes.POST_CAMPAIGN_ANALYTICS_FULFILLED:
      return {
        ...state,
        campaign_analytics: action.payload,
        campaign_analytics_started: true,
      };
    case actionTypes.POST_CAMPAIGN_ANALYTICS_ERROR:
      return {
        ...state,
        campaign_analytics_error: true,
      };
    case actionTypes.POST_DATA_ANALYSIS_FULFILLED:
      return {
        ...state,
        campaign_data_analysis: action.payload,
      };
    case actionTypes.POST_DATA_ANALYSIS_FULFILLED:
      return {
        ...state,
        campaign_data_analysis_error: true,
      };
    default:
      return state;
  }
};
