import * as actionTypes from "../types/organizationCampaign";

import { newRestCall, restCall } from "../../utils/fetch";

import { Dispatch } from "redux";

export const getAllOrganizationCampaigns = (id: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/share/${id}`,
      method: "get",
      successType: actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_FULFILLED,
      errorType: actionTypes.GET_ALL_ORGANIZATION_CAMPAIGNS_ERROR,
      dispatch,
    });
  };
};

export const createShareLink = (obj: any, campaign_id: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/share/create_share_link/${campaign_id}`,
      method: "post",
      data: { object: JSON.stringify(obj) },
      successType: actionTypes.CREATE_CAMPAIGN_SHARE_LINK_FULFILLED,
      errorType: actionTypes.CREATE_CAMPAIGN_SHARE_LINK_ERROR,
      dispatch,
    });
  };
};

export const clearShareLink = (obj: any, campaign_id: string) => ({
  type: actionTypes.CLEAR_CAMPAIGN_SHARE_LINK,
  payload: null,
});

export const postCampaignAnalyticsFilter = (
  obj: any,
  affiliate_id_prefix: string
) => {
  return async function (dispatch: Dispatch) {
    await newRestCall({
      url: `/analytics/filter_campaign_data/${affiliate_id_prefix}`,
      method: "post",
      data: { object: JSON.stringify(obj) },
      startedType: actionTypes.POST_CAMPAIGN_ANALYTICS_STARTED,
      successType: actionTypes.POST_CAMPAIGN_ANALYTICS_FULFILLED,
      errorType: actionTypes.POST_CAMPAIGN_ANALYTICS_ERROR,
      dispatch,
    });
  };
};

export const clearPostCampaignAnalytics = (obj: any, campaign_id: string) => ({
  type: actionTypes.POST_CAMPAIGN_ANALYTICS_STARTED,
  payload: null,
});

export const dataAnalysis = (obj: any, affiliate_id_prefix: string) => {
  return async function (dispatch: Dispatch) {
    await restCall({
      url: `/analytics/data_analysis/${affiliate_id_prefix}`,
      method: "post",
      data: { object: JSON.stringify(obj) },
      successType: actionTypes.POST_DATA_ANALYSIS_FULFILLED,
      errorType: actionTypes.POST_DATA_ANALYSIS_ERROR,
      dispatch,
    });
  };
};
