import "./campaign.css";

import { Box, Button, Modal, Snackbar, Typography } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { useEffect, useRef, useState } from "react";

import Hoc from "../../../hoc/Hoc";

interface EmailCardProps {
  email_object: any;
  createShareLink: Function;
  campaign: any;
  clearShareLink: Function;
}

const sanitizeTemplate = (template: string): string => {
  return template
    .replace(/<\/*(html|head|body)[^>]*>/gi, "") // Remove html, head, body tags
    .trim();
};

const EmailCard: React.FC<EmailCardProps> = ({
  email_object,
  createShareLink,
  campaign,
  clearShareLink,
}) => {
  const { template } = email_object;
  const sanitizedTemplate = sanitizeTemplate(template);
  const contentRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState("auto");
  const [serviceProvider, setServiceProvider] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    campaign_social_share_info,
    campaign_social_share_error,
    organization_campaigns,
  } = campaign;

  const affiliate_id_prefix = organization_campaigns[0]?.affiliate_id_prefix;

  const handleCreateLink = () => {
    if (!serviceProvider) {
      setSnackbarOpen(true);
      return;
    }
    console.log("LOGGER Template = ", email_object)
    createShareLink({ ...email_object, platform: "email" }, affiliate_id_prefix);
  };

  const handleServiceProviderClick = (provider: any) => {
    setServiceProvider(provider);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.transform = "none";
      const { height, width } = contentRef.current.getBoundingClientRect();

      setWrapperHeight(`${height * 0.45 + 115}px `);

      contentRef.current.style.transform = "scaleX(.45) scaleY(0.435)";
      contentRef.current.style.transformOrigin = "top";
    }
  }, [sanitizedTemplate]);

  const handleCopy = async () => {
    const updatedTemplate = template.replace('[link]', campaign_social_share_info.share_url);
    const blob = new Blob([updatedTemplate], { type: "text/html" });
    const data = [new ClipboardItem({ "text/html": blob })];
    navigator.clipboard
      .write(data)
      .then(() =>
        setModalOpen(true)
      )
      .catch((err) => console.error("Failed to copy HTML: ", err));
  };

  const copyToClipboard = () => {
    if (!serviceProvider) {
      setSnackbarOpen(true);
      return;
    }
    const updatedTemplate = template.replace('[link]', campaign_social_share_info.share_url);
    navigator.clipboard.writeText(updatedTemplate).then(
      () => setModalOpen(true),
      () => setModalOpen(true)
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    clearShareLink();
    setServiceProvider(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const actionButton = (
    <Button
      variant="contained"
      onClick={
        campaign_social_share_info &&
        !campaign_social_share_error &&
        serviceProvider
          ? serviceProvider === "Gmail" ? handleCopy : copyToClipboard
          : handleCreateLink
      }
      style={{
        backgroundColor: serviceProvider ? "var(--color-primary)" : "#e0e0e0",
        color: serviceProvider ? "var(--dark-green)" : "#9e9e9e",
      }}
    >
      {campaign_social_share_info &&
      !campaign_social_share_error &&
      serviceProvider
        ? "COPY TEMPLATE"
        : "CREATE EMAIL TEMPLATE"}
    </Button>
  );

  return (
    <div className="email-card-wrapper" style={{ height: wrapperHeight }}>
      <div
        ref={contentRef}
        className="email-card-content"
        dangerouslySetInnerHTML={{ __html: sanitizedTemplate }}
      />
      <div
        style={{
          width: "100%",
          padding: "0px 10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="campaign-email-builder-button-container">
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "MailChimp" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("MailChimp")}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/MailChimp.svg/800px-MailChimp.svg.png" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "ConstantContact" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("ConstantContact")}
          >
            <img src="https://www.constantcontact.com/blog/wp-content/uploads/2022/05/CTCT_Logo_H_Stack_FC_RGB.png" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "SendGrid" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("SendGrid")}
          >
            <img src="https://cdn.worldvectorlogo.com/logos/sendgrid-2.svg" />
          </div>
          <div
            className="campaign-email-builder-button"
            style={{
              backgroundColor:
                serviceProvider === "Gmail" ? "#BBBBBB" : "#e6e6e6",
            }}
            onClick={() => handleServiceProviderClick("Gmail")}
          >
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/512px-Gmail_icon_%282020%29.svg.png" />
          </div>
        </div>

        {actionButton}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Please select a service provider before copying the template.
        </MuiAlert>
      </Snackbar>
      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="copy-success-modal"
        aria-describedby="copy-success-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "20px",
          }}
        >
          <Typography
            id="copy-success-modal"
            variant="h6"
            sx={{ fontSize: 24 }}
          >
            Copy Success!
          </Typography>
          <Typography
            id="copy-success-description"
            sx={{ mt: 2, fontSize: 18 }}
          >
            {`Copied link to clipboard successfully! Paste in ${
              serviceProvider
                ? serviceProvider.charAt(0).toUpperCase() + serviceProvider.slice(1)
                : "E.g. Facebook"
            }.`}
          </Typography>
          <Button
            variant="contained"
            onClick={closeModal}
            sx={{
              mt: 2,
              backgroundColor: "#6b21a8",
              "&:hover": { backgroundColor: "#5a1a8a" },
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Hoc(EmailCard);
