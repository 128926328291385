import React, { useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory";

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Button } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import Hoc from "../../../hoc/Hoc";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { datePickerValues } from "../../../utils/utilities";

const preprocessRevenueData = (data) => {
  return data
    .filter((item) => item.created_at && item.revenue !== undefined)
    .map((item) => ({
      date: new Date(item.created_at).toLocaleDateString("en-US"),
      revenue: item.revenue,
    }));
};

const RevenueLineGraph = ({ charity, login, getCharityTransaction }) => {
  const {
    transactions: { totals = [], influencer_totals, charity_totals },
  } = charity;

  const affiliateIdPrefix =
    login &&
    login.user_info.charity &&
    login.user_info.charity.affiliate_id_prefix;

  const data = totals || [];
  const [dateDisplay, setDateDisplay] = useState({ start: "", end: "" });

  const [value, setValue] = useState(null);

  useEffect(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const formattedToday = today.toISOString();
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString();

    const isoStart =
      value === null
        ? formattedThirtyDaysAgo
        : value &&
          value[0] &&
          value[0].$d &&
          new Date(value[0].$d).toISOString();

    const isoEnd =
      value && value[1] && value[1].$d
        ? new Date(value[1].$d).toISOString()
        : formattedToday;

    const formatDateToMDY = (dateString) => {
      const date = new Date(dateString);
      return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
    };

    const displayStart = formatDateToMDY(isoStart);
    const displayEnd = formatDateToMDY(isoEnd);

    setDateDisplay({ start: displayStart, end: displayEnd });
    getCharityTransaction(affiliateIdPrefix, isoStart, isoEnd);
  }, [value]);

  useEffect(() => {
    if (value) {
      const dates = datePickerValues(value);
      setDateDisplay(dates);
      getCharityTransaction(affiliateIdPrefix, dates.start, dates.end);
    }
  }, [value]);

  const revenueData = preprocessRevenueData(data);

  const formattedGraphData = data.map((data) => {
    const date =
      data && data.created_at ? data.created_at.split("T")[0].split("-") : "";
    return {
      created_at: date === "" ? "" : `${date[1]}-${date[2]}-${date[0]}`,
      revenue: Number(Math.round((data.revenue * 100) / 100).toFixed(2)),
    };
  });

  const sumAllRevenue = (arr) => {
    var sum = arr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.revenue;
    }, 0);
    return sum;
  };

  const sumAllRevenueTest = (arr) => {
    const sum = sumAllRevenue(arr);
    const stripeFee = sum * (2.9 / 100) + 0.3;
    const netRevenue = sum - stripeFee;
    return netRevenue.toFixed(2);
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "80%", display: "flex" }}>
        <div
          style={{
            display: "flex",
            margin: "20px 0",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "40%", margin: "0 30px 0 0" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateRangePicker"]}>
                <DateRangePicker
                  onChange={setValue}
                  localeText={{
                    start: dateDisplay.start,
                    end: dateDisplay.end,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div style={{ width: "100", display: "flex" }}>
        <div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <VictoryChart
            theme={VictoryTheme.material}
            domainPadding={{ x: 20 }}
            padding={{ top: 35, bottom: 0, left: 40, right: 10 }}
            width={800}
            height={400}
            style={{
              background: { fill: "rgb(15 23 42 / 14%)" },
            }}
          >
            <VictoryLegend
              x={250}
              y={10}
              orientation="horizontal"
              gutter={20}
              style={{
                labels: { fontSize: 12, fill: "#000" },
              }}
              data={[{ name: "Revenue", symbol: { fill: "#00c7a4" } }]}
            />

            <VictoryAxis
              label="Date"
              tickFormat={(t) => t}
              style={{
                axis: { stroke: "#756f6a" },
                axisLabel: { fontSize: 14, padding: 30, fill: "#000" },
                ticks: { stroke: "#756f6a", size: 5 },
                tickLabels: { fontSize: 10, padding: 5, fill: "#000" },
              }}
            />

            <VictoryAxis
              dependentAxis
              label="Revenue ($)"
              style={{
                axis: { stroke: "#756f6a" },
                axisLabel: { fontSize: 14, padding: 40, fill: "#000" },
                ticks: { stroke: "#756f6a", size: 5 },
                tickLabels: { fontSize: 10, padding: 5, fill: "#000" },
              }}
            />

            <VictoryLine
              data={revenueData}
              x="date"
              y="revenue"
              style={{
                data: { stroke: "#00c7a4", strokeWidth: 2 },
                labels: { fontSize: 10, fill: "#000" },
              }}
            />

            <VictoryScatter
              data={revenueData}
              x="date"
              y="revenue"
              size={4}
              style={{
                data: { fill: "#00c7a4" },
              }}
              labels={({ datum }) => `$${datum.revenue.toFixed(2)}`}
              labelComponent={
                <VictoryTooltip
                  style={{ fontSize: 10, fill: "#000" }}
                  flyoutStyle={{
                    fill: "#fff",
                    stroke: "#00c7a4",
                    strokeWidth: 1,
                  }}
                />
              }
            />
          </VictoryChart>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            padding: "45px 0 0 20px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="graph-top-container">
            <Button
              variant="contained"
              style={{
                width: "180px",
                backgroundColor: "var(--color-primary)",
                color: "var(--dark-green)",
              }}
              disable={true}
            >
              Total: $
              {sumAllRevenueTest(formattedGraphData) <= 0
                ? 0
                : sumAllRevenueTest(formattedGraphData)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hoc(RevenueLineGraph);
