import "./Merchant.css";

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

import Button from "../../button/SignUp";
import { merchant_array } from "../../../utils/charity";

interface IMerchant {
  button_text?: string;
  button_link?: string;
}

const Merchant: React.FC<IMerchant> = ({ button_text, button_link }) => {
  const [merchantArray, setMerchantArray] = useState<any>(merchant_array);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const translateConvert = (translateNumber: number) => {
    var number = translateNumber;
    if (isSmallScreen) number = translateNumber / 2;
    return `translate(${number}px, -${number}px)`;
  };

  useEffect(() => {
    var merchantArrayCopy = [...merchantArray];
    setTimeout(() => {
      merchantArrayCopy.push(merchantArrayCopy.splice(0, 1)[0]);
      setMerchantArray(merchantArrayCopy);
    }, 1100);
  }, [setMerchantArray, merchantArray]);

  const styles = useSpring({
    from: { opacity: 1, transform: `translate(0px, 0px)` },
    to: {
      opacity: 0,
      transform: translateConvert(45),
    },
    config: {
      duration: 500,
      mass: 1,
      tension: 180,
      friction: 12,
    },
    reset: true,
  });

  const indexOne = useSpring({
    from: { opacity: 1, transform: translateConvert(45) },
    to: {
      transform: `translate(0px, 0px)`,
    },
    config: {
      mass: 1,
      tension: 180,
      friction: 12,
    },
    reset: true,
  });

  const indexTwo = useSpring({
    from: { opacity: 1, transform: translateConvert(90) },
    to: {
      transform: translateConvert(45),
    },
    config: {
      mass: 1,
      tension: 200,
      friction: 20,
    },
    reset: true,
  });

  const indexThree = useSpring({
    from: { opacity: 1, transform: translateConvert(135) },
    to: {
      transform: translateConvert(90),
    },
    config: {
      mass: 1,
      tension: 220,
      friction: 28,
    },
    reset: true,
  });

  const indexFourth = useSpring({
    from: { opacity: 1, transform: translateConvert(180) },
    to: {
      transform: translateConvert(135),
    },
    config: {
      mass: 1,
      tension: 240,
      friction: 36,
    },
    reset: true,
  });

  const styleHandler = (brand: any, index: number) => {
    switch (index) {
      case 0:
        return {
          ...styles,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 15,
        };
      case 1:
        return {
          ...indexOne,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 6,
        };
      case 2:
        return {
          ...indexTwo,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 5,
        };
      case 3:
        return {
          ...indexThree,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 4,
        };
      case 4:
        return {
          ...indexFourth,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 3,
        };
      case 5:
        return {
          ...indexFourth,
          backgroundImage: `url(${brand.background_image})`,
          zIndex: 2,
        };
      default:
    }
  };

  return (
    <div className="merchant-container ">
      <div className="merchant-text-container">
        <h2>Works at</h2>
        <h1 style={{ color: "var(--color-secondary)" }}>48,000 stores</h1>
        <Button
          main_color={"var(--color-secondary"}
          secondary_color={"var(--dark-green)"}
          text={button_text}
          link={button_link}
        />
      </div>
      <div className="merchant-animation-container">
        {merchantArray.map((brand: any, idx: number) => (
          <animated.div
            className={`merchant-card ${idx}`}
            key={idx}
            style={styleHandler(brand, idx)}
          ></animated.div>
        ))}
      </div>
    </div>
  );
};
export default Merchant;
