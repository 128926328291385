import { Dispatch, bindActionCreators, compose } from "redux";
import {
  clearPostCampaignAnalytics,
  clearShareLink,
  createShareLink,
  dataAnalysis,
  getAllOrganizationCampaigns,
  postCampaignAnalyticsFilter
} from "../store/actions/organizationCampaign";
import {
  clearPostContact,
  deleteContactById,
  getCharityShareAnalytics,
  getCharityTransaction,
  getEmailAddress,
  getEmailAnalytics,
  getOrganizationByState,
  postCharityShareAnalytics,
  postCharityShareAnalyticsClear,
  postContact,
  postEmailAnalytics,
  postEmailAnalyticsClear,
} from "../store/actions/charity";
import {
  getAllCouponsForBrand,
  getStoreNames,
  getTrendingStores,
  setValue,
} from "../store/actions/coupons";

import React from "react";
import { connect } from "react-redux";
import { loginUser } from "../store/actions/login";
import { postContactMessage } from "../store/actions/contact";

const mapStateToProps = (state: IState) => {
  const { login, coupons, charity, contact_message_success, campaign } = state;
  return {
    login,
    coupons,
    charity,
    contact_message_success,
    campaign,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loginUser: loginUser,
      getStoreNames: getStoreNames,
      getAllCouponsForBrand: getAllCouponsForBrand,
      getTrendingStores: getTrendingStores,
      setValue: setValue,
      getCharityTransaction: getCharityTransaction,
      getCharityShareAnalytics: getCharityShareAnalytics,
      postCharityShareAnalytics: postCharityShareAnalytics,
      postCharityShareAnalyticsClear: postCharityShareAnalyticsClear,
      getEmailAnalytics: getEmailAnalytics,
      postEmailAnalytics: postEmailAnalytics,
      postEmailAnalyticsClear: postEmailAnalyticsClear,
      postContact: postContact,
      clearPostContact: clearPostContact,
      getEmailAddress: getEmailAddress,
      postContactMessage: postContactMessage,
      getOrganizationByState: getOrganizationByState,
      deleteContactById: deleteContactById,
      getAllOrganizationCampaigns: getAllOrganizationCampaigns,
      createShareLink: createShareLink,
      clearShareLink: clearShareLink,
      postCampaignAnalyticsFilter: postCampaignAnalyticsFilter,
      
      clearPostCampaignAnalytics:clearPostCampaignAnalytics,
      dataAnalysis: dataAnalysis
    },
    dispatch
  );

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Hoc = (OriginalComponent: any) => {
  const NewComponent: React.FC<Props> = ({ ...Props }) => {
    return <OriginalComponent {...Props} />;
  };
  return NewComponent;
};

const composeWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  Hoc
);

export default composeWrapper;
