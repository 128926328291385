import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import React from "react";

const DynamicSelect = ({
  label,
  name,
  value,
  onChange,
  options,
  style,
  selectProps,
}) => {
  return (
    <div style={style}>
      <FormControl fullWidth>
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          name={name}
          value={value}
          label={label}
          onChange={(e) => {
            onChange(e);
          }}
          {...selectProps}
        >
          {options.map((option, index) =>
            typeof option === "string" ? (
              <MenuItem key={index} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </MenuItem>
            ) : (
              <MenuItem key={option.campaign_title} value={option.campaign_title}>
                {option.campaign_title}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default DynamicSelect;