import React, { useEffect, useState } from "react";

import axios from "axios";
import { useSearchParams } from "react-router-dom";

const AuthorizationHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleOAuthRedirect = async () => {
      const code = searchParams.get("code");
      const userOrganizationId = searchParams.get("state");
      console.log("LOGGER ", { code, userOrganizationId });
      if (!code || !userOrganizationId) {
        setError("Missing authorization code or userOrganizationId.");
        return;
      }

      setLoading(true);

        try {
          const response = await axios.post("http://localhost:8080/promotions/get-token-tremendous", {
            code,
            userOrganizationId,
          });
          setSuccessMessage("Authorization successful! Server responded: " + response.data.message);
        } catch (err) {
          setError("Failed to handle OAuth response. Please try again.");
          console.error(err);
        } finally {
          setLoading(false);
        }
    };

    handleOAuthRedirect();
  }, [searchParams]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p style={{ color: "red" }}>{error}</p>
      ) : successMessage ? (
        <p style={{ color: "green" }}>{successMessage}</p>
      ) : (
        <a href="/login">LOGIN</a>
      )}
    </div>
  );
};

export default AuthorizationHandler;
